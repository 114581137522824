.c-carousel-root {
  width: 100%;
  height: 100vh;
  position: relative;
  overflow: hidden;
  .c-carousel-main {
    height: 100vh;
    position: absolute;
    display: flex;
    align-items: center;
  }
  .arrow-carousel {
    color: white;
    position: absolute;
    top: 50%;
    font-size: 40px;
    z-index: 2;
    cursor: pointer;
    transform: translateY(-50%);
    &.left {
      left: 20px;
    }
    &.right {
      right: 20px;
    }
  }
  .list-control {
    position: absolute;
    z-index: 999;
    bottom: 80px;
    left: 50%;
    transform: translateX(-50%);
    display: flex;
    gap: 8px;
    > div {
      width: 8px;
      height: 8px;
      border-radius: 50%;
      background-color: #d4d4d4;
      opacity: 0.3;
      transition: all 0.4s ease-out;
      cursor: pointer;
      &.active {
        background-color: #d4d4d4;
        opacity: 1;
      }
    }
  }
}
