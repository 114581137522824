.footer-ssg {
  position: fixed;
  bottom: 0;
  padding: 0 20px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  box-sizing: border-box;
  width: 100%;
  background: #121212;
  font-family: Lato;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 14px;
  color: #878787;
  z-index: 999;

  .contact-icon {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 36px;
    a {
      text-decoration: none;
      color: #878787;
      font-size: 24px;
    }
  }
}

@media only screen and (max-width: 600px) {
  .footer-ssg {
    justify-content: center;
    .title {
      font-size: 8px;
      span {
        display: block;
      }
    }
    .contact-icon {
      gap: 10px;
      ion-icon {
        font-size: 16px;
      }
    }
    .tvo-credit{
      display: none;
    }
  }
}
