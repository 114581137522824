* {
  margin: 0;
  padding: 0;
  font-family: 'Montserrat', sans-serif;
  font-weight: 300;
}

.App {
  width: 100vw;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}

body {
  &::-webkit-scrollbar {
    display: none;
  }
  background-color: #000 !important;
  font-family: 'Montserrat', sans-serif;
}

@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400;700&display=swap');

// @font-face {
//   font-family: "Lato";
//   src: url("assets/font/lato/Lato\ Regular.ttf") format("truetype");
//   font-weight: normal;
//   font-style: normal;
// }

// @font-face {
//   font-family: "Lato";
//   src: url("assets/font/lato/Lato\ Medium.ttf") format("truetype");
//   font-weight: 500;
//   font-style: normal;
// }
// @font-face {
//   font-family: "Lato";
//   src: url("assets/font/lato/Lato\ Bold.ttf") format("truetype");
//   font-weight: 600;
//   font-style: normal;
// }
// @font-face {
//   font-family: "Lato";
//   src: url("assets/font/lato/Lato\ Semibold.ttf") format("truetype");
//   font-weight: 600;
//   font-style: normal;
// }
// @font-face {
//   font-family: "Baskerville";
//   src: url("assets/font/NewBaskerville/SVN-NewBaskerville-Black.ttf")
//     format("truetype");
//   font-weight: normal;
//   font-style: normal;
// }

// @font-face {
//   font-family: "Baskerville";
//   src: url("assets/font/NewBaskerville/SVN-NewBaskerville-SemiBold.ttf")
//     format("truetype");
//   font-weight: 500;
//   font-style: normal;
// }
// @font-face {
//   font-family: "Baskerville";
//   src: url("assets/font/NewBaskerville/SVN-NewBaskerville-SemiBold.ttf")
//     format("truetype");
//   font-weight: 600;
//   font-style: normal;
// }
// @font-face {
//   font-family: "Baskerville";
//   src: url("assets/font/NewBaskerville/SVN-NewBaskerville-Bold.ttf")
//     format("truetype");
//   font-weight: 700;
//   font-style: normal;
// }
// @font-face {
//   font-family: "Baskerville";
//   src: url("assets/font/NewBaskerville/SVN-NewBaskerville-Bold.ttf")
//     format("truetype");
//   font-weight: 800;
//   font-style: normal;
// }
// @font-face {
//   font-family: "Baskerville";
//   src: url("assets/font/NewBaskerville/SVN-NewBaskerville-Roman.ttf")
//     format("truetype");
//   font-weight: 300;
//   font-style: normal;
// }
// @font-face {
//   font-family: "Baskerville";
//   src: url("assets/font/NewBaskerville/SVN-NewBaskerville-Roman.ttf")
//     format("truetype");
//   font-weight: 400;
//   font-style: normal;
// }
