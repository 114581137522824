.artists-detail {
  width: 100%;
  background-color: #181817;
  display: flex;
  justify-content: center;
  font-family: "Baskerville", sans-serif;
  color: white;
  flex: 1;
  .content {
    margin-top: 36px;
    padding: 0 16px;
    max-width: 1040px;
    margin-bottom: 200px;
    .info {
      display: flex;
      gap: 36px;
      flex-wrap: wrap;
      .image {
        img {
          width: 100%;
        }
      }
      > div {
        flex: 1;
      }

      .details {
        max-width: 500px;
        .title {
          font-weight: 600;
          font-size: 24px;
          line-height: 29px;
          margin-bottom: 6px;
        }
        .dob{
          font-weight: 500;
          font-size: 22x;
          line-height: 29px;
          margin-top: 20px;
        }
        .big-hits{
          font-weight: 500;
          font-size: 22x;
          line-height: 29px;
          margin-top: 20px;
        }
        .name {
          font-weight: bold;
          font-size: 64px;
          line-height: 77px;
          margin-bottom: 16px;
        }
        .brief-introduction{
          margin-top: 20px;
        }

        .contact-icon {
          display: flex;
          flex-direction: row;
          gap: 16px;
          margin-bottom: 20px;
          margin-top: 20px;

          > a {
            width: 32px;
            height: 32px;
            border-radius: 50%;
            background-color: #4c4c4c;
            display: flex;
            justify-content: center;
            align-items: center;
            ion-icon {
              font-size: 18px;
              color: white;
            }
          }
        }
        .introduce {
          max-width: 500px;
          font-family: Baskerville;
          font-style: normal;
          font-weight: normal;
          font-size: 18px;
          line-height: 25px;
          color: #bdbebf;
          display: flex;
          flex-direction: column;
          gap: 30px;
        }
      }
    }

    .production {
      margin-top: 98px;
      .title {
        font-weight: bold;
        font-size: 24px;
        line-height: 29px;
        color: #d4d4d4;
      }
      .video {
        width: 100%;
        padding-top: 55%;
        position: relative;
        margin-top: 36px;
        iframe {
          position: absolute;
          width: 100%;
          height: 100%;
          top: 0;
          left: 0;
        }
      }
      .list-thumbnail {
        margin-top: 23px;
        height: 154px;
        .item-c-carousel {
          .thumbnail-item {
            height: 138px;
            width: 245px;
            padding: 0 10px;
            box-sizing: border-box;
            img {
              cursor: pointer;
              width: 100%;
              height: 100%;
              object-fit: cover;
            }
          }
        }
        .list-control {
          display: none;
        }
      }
    }
    .discover {
      margin-top: 36px;
      .title {
        font-weight: bold;
        font-size: 24px;
        line-height: 29px;
        color: #d4d4d4;
        margin-bottom: 12px;
        user-select: none;
      }
    }
    @media only screen and (max-width: 600px) {
      .info {
        > div {
          flex: auto;
        }
        .details {
          .name {
            font-size: 32px !important;
          }
          .title {
            font-size: 16px !important;
          }
          .introduce {
            font-size: 13px !important;
          }
        }
        .production {
          .title {
            font-size: 16px !important;
          }
        }
        .discover {
          .title {
            font-size: 16px !important;
          }
        }
      }
    }
  }
}
