.project {
  color: white;
  font-family: Lato;
  background-color: #181817;
  min-height: 100vh;
  padding-bottom: 80px;
  .main {
    max-width: 1040px;
    padding: 10px;
    margin: 0 auto;
    > .title {
      margin: 40px auto;
      font-weight: bold;
      font-size: 48px;
      line-height: 58px;
      text-align: center;
    }
    .image-project {
      width: 100%;
      img {
        width: 100%;
      }
    }
    .topic {
      margin: 40px 0;
      > .title {
        font-weight: 600;
        font-size: 18px;
        line-height: 30px;
      }
      > .content {
        font-weight: 500;
        margin-top: 10px;
        font-size: 16px;
        line-height: 30px;
        color: #bdbebf;
      }
    }
    .project-title{
      font-size: x-large;
      font-weight: 600;
    }
    .brief-intro{
      margin-top: 10px;
      font-size: larger;
    }
    .list-project {
      > .title {
        margin: 40px auto;
        font-weight: bold;
        font-size: 48px;
        line-height: 58px;
        text-align: center;
        margin-bottom: 40px;
      }
      .new-image{
        min-height: 150px;
        max-width: 250px;
        height: 250px;
        object-fit: cover;
        @media only screen and (max-width: 600px) {
          max-width: none;
        }
      }
      .list {
        display: flex;
        padding: 10px;
        flex-wrap: wrap;
        gap: 20px;
        .item-project {
          cursor: pointer;
          display: flex;
          width: 100%;
          background: #2c2c2c;
          @media only screen and (max-width: 600px) {
            flex-direction: column;
            padding: 10px;
          }
          img {
            width: 100%;
            object-fit: cover;
          }
          .title-item {
            width: 100%;
            font-weight: 600;
            font-size: 16px;
            text-align: justify;
            padding: 15px 15px;
            @media only screen and (max-width: 600px) {
              padding: 0px;
            }
          }
        }
      }
    }
  }
}

.ssg-vna {
  color: white;
  font-family: Lato;
  background-color: #181817;
  min-height: 100vh;
  padding-bottom: 80px;
  main {
    margin: auto;
    padding: 10px;
    max-width: 1040px;
    margin-top: 36px;
    > img {
      width: 100%;
    }
    .topic {
      margin-top: 16px;
      desc {
        font-style: normal;
        font-weight: 600;
        font-size: 24px;
        line-height: 29px;
        margin-bottom: 6px;
      }
      .title {
        font-weight: 600;
        font-size: 40px;
        line-height: 77px;
        margin-bottom: 32px;
      }
      .content {
        font-style: normal;
        font-weight: normal;
        font-size: 18px;
        line-height: 25px;
        color: #bdbebf;
        margin-bottom: 40px;
        div {
          img {
            width: 50%;
            box-sizing: border-box;
            float: left;
            padding-right: 32px;
            margin-bottom: 40px;
          }
          @media screen and (max-width: 500px) {
            img {
              width: 100%;
              padding: 0;
            }
          }
        }
      }
    }
    .list-image {
      .title-image {
        font-weight: bold;
        font-size: 24px;
        line-height: 29px;
        margin-bottom: 12px;
      }
      .c-carousel-2 {
        .content-item {
          width: 100%;
          height: 100%;
          img {
            width: 100%;
            height: 100%;
            object-fit: cover;
          }
        }
      }
    }
    .list-project {
      margin-bottom: 97px;
      > .title {
        margin: 40px auto;
        font-weight: bold;
        font-size: 24px;
        line-height: 29px;
        margin-bottom: 12px;
      }
      .list {
        display: grid;
        grid-template-columns: auto auto auto auto;
        flex-wrap: wrap;
        gap: 20px;
        .item-project {
          cursor: pointer;
          display: flex;
          width: 100%;
          background: #2c2c2c;
          img {
            width: 100%;
            object-fit: cover;
          }
          .title-item {
            width: 100%;
            font-weight: 600;
            font-size: 16px;
            text-align: justify;
            padding: 15px 15px;
          }
        }
      }
    }
  }

  .c-carousel-root {
    width: 100%;
    height: 160px;
    .thumbnail-item {
      display: inline-flex;
      width: 300px;
      margin: 0 10px;
      img {
        width: 100%;
        object-fit: cover;
      }
    }
    .list-control {
      display: none;
    }
  }
}

.space-jam {
  @extend .ssg-vna;
}
.muzik {
  @extend .ssg-vna;
}
.SSTV {
  @extend .ssg-vna;
}

@media only screen and (max-width: 600px) {
  .ssg-vna {
    .list-project {
      > .title {
        font-size: 32px !important;
      }
      .list {
        grid-template-columns: auto auto !important;
      }
    }
  }
  .project {
    .main {
      > .title {
        font-size: 32px !important;
      }
      .list-project {
        > .title {
          font-size: 32px !important;
        }
        .list {
          grid-template-columns: auto auto;
        }
      }
    }
  }
  .ssg-vna {
    .topic {
      desc {
        font-size: 20px !important;
      }
    }
    .title {
      font-size: 32px !important;
    }
  }
}
