.discography-ssg {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #181817;
  font-family: "Baskerville";
  padding-bottom: 60px;
  flex: 1;
  .discography-all{
    display: grid;
    grid-template-columns: auto auto auto auto auto;
    @media only screen and (max-width: 1800px) {
      grid-template-columns:auto auto auto auto;
    }
    @media only screen and (max-width: 1600px) {
      grid-template-columns:auto auto auto;
    }
    @media only screen and (max-width: 700px) {
      grid-template-columns:auto auto ;
    }
  }
  .title {
    color: #fff;
    font-weight: 700;
    @media only screen and (min-width: 200px) {
      font-size: 32px;
    }
    @media only screen and (min-width: 1024px) {
      font-size: 48px;
    }
  }
  .tile-mb {
    margin-bottom: 40px;
    margin-top: 40px;
  }
  .song-display-image{
    width: 320px;
    height: 180px;
    object-fit: cover;
    position: relative;
    @media only screen and (max-width: 1800px) {
      width: 300px ;
      height: 169px ;
    }
    @media only screen and (max-width: 1600px) {
      width: 250px  ;
      height: 140px  ;
    }
    @media only screen and (max-width: 1200px) {
      width: 200px  ;
      height: 112.5px  ;
    }
    @media only screen and (max-width: 1000px) {
      width: 150px  ;
      height: 85px  ;
    }
  }
  .to-ut-button{
    max-width: 220px;
    width: 10vw;
    height: 30px;
    border: 3px solid white;
    top: 50px;
    left: 55px;
    color: white;
    text-align: center;
    border-radius: 4px;
    position: absolute;
    text-decoration: none;
    padding: 5px;
    padding-top: 12px;
    @media only screen and (max-width: 1600px) {
      // width: 300px ;
      // height: 300px ;
      left: 40px;
      top: 120px;
    }
    @media only screen and (max-width: 1600px) {
      // width: 300px ;
      // height: 300px ;
      left: 40px;
      top: 100px;
    }
    @media only screen and (max-width: 1400px) {
      // width: 300px ;
      // height: 300px ;
      left: 55px;
      top: 85px;
    }
    @media only screen and (max-width: 1100px) {
      // width: 300px ;
      // height: 300px ;
      top: 80px;
      left: 40px;
    }
  }
  .song-display-image:hover{
    transition-duration: 300ms;
    filter: blur(5px);
  }
  .button-listen{
    position: absolute;
    top: 180px;
    left: 5vw;
    height: 25px;
    color: white;
    background-color: transparent;
    border: 2px solid white;
    border-radius: 4px;
    padding: 10px;
    width: 150px;
    text-align: center;
    text-decoration: unset;
    ;
  }
  .content-container {
    cursor: pointer;
    display: flex;
    flex-direction: row;
    justify-content: center;
    color: #fff;
    .box {
      .margin-box {
        position: relative;
        @media only screen and (min-width: 200px) {
          margin: 12px 4px 12px 4px;
        }
        @media only screen and (min-width: 1024px) {
          margin: 0 10px 40px 10px;
        }
        .title-song {
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-line-clamp: 1;
          line-clamp: 1;
          -webkit-box-orient: vertical;
          font-weight: 600;
          margin-top: 12px;
          margin-bottom: 8px;
          @media only screen and (min-width: 200px) {
            width: 168px;
            font-size: 14px;
          }
          @media only screen and (min-width: 1024px) {
            width: 245px;
            font-size: 16px;
          }
        }
        .des {
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-line-clamp: 1;
          line-clamp: 1;
          -webkit-box-orient: vertical;
          @media only screen and (min-width: 200px) {
            width: 168px;
            font-size: 10px;
          }
          @media only screen and (min-width: 1024px) {
            width: 245px;
            font-size: 14px;
          }
          font-weight: 400;
        }
      }
    }
  }
  .gooey {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 142px;
    height: 40px;
    margin: -20px 0 0 -71px;
    filter: contrast(20);

    .dot {
      position: absolute;
      width: 16px;
      height: 16px;
      top: 12px;
      left: 15px;
      filter: blur(4px);
      background: #fff;
      border-radius: 50%;
      transform: translateX(0);
      animation: dot 2.8s infinite;
    }

    .dots {
      transform: translateX(0);
      margin-top: 12px;
      margin-left: 31px;
      animation: dots 2.8s infinite;

      span {
        display: block;
        float: left;
        width: 16px;
        height: 16px;
        margin-left: 16px;
        filter: blur(4px);
        background: #fff;
        border-radius: 50%;
      }
    }
  }

  @-moz-keyframes dot {
    50% {
      transform: translateX(96px);
    }
  }

  @-webkit-keyframes dot {
    50% {
      transform: translateX(96px);
    }
  }

  @-o-keyframes dot {
    50% {
      transform: translateX(96px);
    }
  }

  @keyframes dot {
    50% {
      transform: translateX(96px);
    }
  }

  @-moz-keyframes dots {
    50% {
      transform: translateX(-31px);
    }
  }

  @-webkit-keyframes dots {
    50% {
      transform: translateX(-31px);
    }
  }

  @-o-keyframes dots {
    50% {
      transform: translateX(-31px);
    }
  }

  @keyframes dots {
    50% {
      transform: translateX(-31px);
    }
  }
}
