.artists-ssg {
  width: 100%;
  background-color: #181817;
  flex: 1;
  display: flex;
  .c-carousel-root {
    flex: 1;
    height: 100vh;
  }
  font-family: "Baskerville";
  .c-carousel-main {
    margin-top: -25px;
    .main-item {
      cursor: pointer;
      user-select: none;
      width: 460px;
      box-sizing: border-box;
      padding: 0 20px;
      img {
        width: 25vw;
        object-fit: contain;
        @media only screen and (min-width: 1800px) {
          width: 20vw;
        }
        @media only screen and (min-width: 2300px) {
          width: 15vw;
        }
       
      }
      .name {
        font-weight: bold;
        font-size: 24px;
        line-height: 29px;
        color: #ffffff;
        margin-top: 10px;
      }

      &.first {
        color: white;
        text-align: right;
        .title {
          font-style: normal;
          font-weight: 600;
          font-size: 32px;
          line-height: 38px;
        }
        .content {
          font-weight: bold;
          font-size: 80px;
          line-height: 96px;
        }
        .content-space {
          margin-right: -27px;
        }
      }
    }
  }
}

@media only screen and (max-width: 700px) {
  .content-mobile {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
    padding: 0 40px;
    gap: 24px;
    margin-bottom: 70px;
    .main-item {
      color: white;
      img {
        width: 100%;
      }
      .name {
        font-weight: bold;
        font-size: 20px;
        line-height: 24px;
        margin-top: 12px;
      }
      &.first {
        margin-top: 24px;
        .title {
          // margin-left: 30%;
          text-align: center;
          font-weight: 600;
          font-size: 16px;
          line-height: 19px;
        }
        .content {
          text-align: center;
          font-weight: bold;
          font-size: 35px;
          line-height: 42px;
          text-align: center;
        }
      }
    }
  }
}
