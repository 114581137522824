.header-app {
  background: #000;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 70px;
  // overflow-x: scroll;
  .logo {
    height: 52px;
    cursor: pointer;
    margin-top: 20px;
    img {
      height: 100%;
    }
    @media only screen and (max-width: 600px) {
      margin-top: 0px;
    }
  }
  padding: 0 36px;
  position: sticky;
  z-index: 999;
  top: 0;

  .btn-menu {
    display: flex;
    cursor: pointer;
    position: relative;
    z-index: 1000;
    transform: scale(0.9);
    align-items: center;
    ion-icon {
      font-size: 30px;
      color: #fff;
    }
  }
  .change-language{
    margin-right: 25px;
  }
  .menu-header {
    z-index: -1;
    position: fixed;
    width: 100%;
    height: 100%;
    background-color: #000;
    top: 0;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    .list-menu {
      margin-bottom: 60px;
      margin-top: 20px;
      justify-content: center;
      height: calc(100vh - 80px);
      display: flex;
      flex-direction: column;
      .menu-item {
        letter-spacing: 10px;
        text-decoration: none;
        color: white;
        height: 88px;
        // width: 390px;
        font-family:'Montserrat';
        font-weight: 300;
        font-size: 32px;
        line-height: 38px;
        text-align: center;
        display: flex;
        justify-content: center;
        align-items: center;
        &:last-child {
          border: none;
        }
      }
      .menu-item:hover{
      }
    }

    .translate {
      color: white;
      height: 88px;
      width: 390px;
      display: flex;
      justify-content: center;
      align-items: center;
      font-weight: bold;
      font-size: 24px;
      font-family: "Baskerville", sans-serif;
      line-height: 29px;
      gap: 20px;
      div {
        cursor: pointer;
      }
      .dash {
        width: 2px;
        height: 25px;
        background-color: white;
      }
    }
    opacity: 0;
    transition: all 0.5s ease-out;
    visibility: hidden;
    &.show-menu {
      visibility: visible;
      opacity: 0.8;
      z-index: 999;
    }
  }
}
@media only screen and (max-width: 600px) {
  .header-app {
    padding: 0px !important;
    .btn-menu {
      transform: scale(0.7);
    }
    .menu-header {
      .list-menu {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        .menu-item {
          height: 70px;
          width: 242px;
          font-weight: bold;
          font-size: 18px;
          line-height: 22px;
        }
        .translate {
          font-weight: bold;
          font-size: 16px;
          line-height: 19px;
          height: 70px;
          .dash {
            height: 16px;
          }
        }
      }
    }
  }
}
