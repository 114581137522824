@import url("https://fonts.googleapis.com/css?family=Lobster");

body {
  background-color: #89fad0;
  font-family: "Lobster";
}

.c-carousel-2 {
  height: 250px;
  width: 100%;
  position: relative;
  overflow: hidden;
  .show-item {
    position: fixed;
    top: 0;
    left: 0;
    background-color: #0e0e0ea1;
    width: 100%;
    height: 100%;
    text-align: center;
    z-index: 10000;
    display: flex;
    justify-content: center;
    align-items: center;
    img {
      max-width: 80%;
      width: 800px;
      object-fit: cover;
      transform: scale(0);
      animation: rotate 0.3s ease-out forwards;
      @keyframes rotate {
        from {
          transform: scale(0);
        }
        to {
          transform: scale(1);
        }
      }
    }
  }
}

#carousel {
  height: 250px;
  width: 1040px;
  margin: auto;
  left: 50%;
  position: absolute;
  transform: translateX(-50%);
}

.arrow {
  position: absolute;
  font-size: 25px;
  cursor: pointer;
  font-size: 20px;
  color: white;
  line-height: 30px;
  top: 50%;
  transform: translateY(-50%);
  z-index: 300;
}

.arrow-right {
  right: 20px;
}
.arrow-left {
  left: 20px;
}

.item {
  text-align: center;
  background: #121212;
  color: white;
  font-size: 40px;
  position: absolute;
  transition: height 1s, width 1s, left 1s, margin-top 1s, line-height 1s,
    background-color 1s;
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

$level2-height: 175px;
$level2-width: 175px;
$level1-height: 200px;
$level1-width: 200px;
$level0-height: 250px;
$level0-width: 250px;

$level-2-left: 1040px -$level2-width;
$level-1-left: 1040px - 10px -$level2-width - $level1-width;
$level0-left: (1040px - $level0-width)/2;
$level1-left: $level2-width + 10px;
$level2-left: 0;

.level-2 {
  height: $level2-height;
  width: $level2-width;
  line-height: $level2-height;
  left: $level-2-left;
  margin-top: 37px;
}

.level-1 {
  height: $level1-height;
  width: $level1-width;
  line-height: $level1-height;
  left: $level-1-left;
  margin-top: 25px;
}

.level0 {
  height: $level0-height;
  width: $level0-width;
  line-height: $level0-height;
  left: $level0-left;
}

.level1 {
  height: $level1-height;
  width: $level1-width;
  line-height: $level1-height;
  margin-top: 25px;
  left: $level1-left;
}

.level2 {
  height: $level2-height;
  width: $level2-width;
  line-height: $level2-height;
  margin-top: 37px;
  left: $level2-left;
}

.left-enter {
  opacity: 0;
  left: $level2-left - $level2-width;
  height: $level2-height - 30;
  width: $level2-width - 20;
  line-height: $level2-height - 30;
  margin-top: 50px;
}

.left-enter.left-enter-active {
  opacity: 1;
  left: $level2-left;
  height: $level2-height;
  width: $level2-width;
  line-height: $level2-height;
  margin-top: 37px;
  transition: left 1s, opacity 1s, height 1s, width 1s, margin-top 1s,
    line-height 1s;
}

.left-leave {
  opacity: 1;
  left: $level-2-left;
  height: $level2-height;
  width: $level2-width;
  line-height: $level2-height;
  margin-top: 37px;
}

.left-leave.left-leave-active {
  left: $level-2-left + $level2-width + 20;
  opacity: 0;
  height: $level2-height - 30;
  line-height: 120px;
  margin-top: 50px;
  width: $level2-width - 20;
  transition: left 1s, opacity 1s, height 1s, width 1s, margin-top 1s,
    line-height 1s;
}

.right-enter {
  opacity: 0;
  left: $level-2-left + $level2-width;
  height: $level2-height - 30;
  width: $level2-width - 20;
  line-height: $level2-height - 30;
  margin-top: 40px;
}

.right-enter.right-enter-active {
  left: $level-2-left;
  opacity: 1;
  height: $level2-height;
  margin-top: 37px;
  line-height: $level2-height;
  width: $level2-width;
  transition: left 1s, opacity 1s, height 1s, width 1s, margin-top 1s,
    line-height 1s;
}

.right-leave {
  left: $level2-left;
  height: $level2-height;
  opacity: 1;
  margin-top: 37px;
  line-height: $level2-height;
  width: $level2-width;
}

.right-leave.right-leave-active {
  left: $level2-left - $level2-width;
  opacity: 0;
  height: $level2-height - 30;
  width: $level2-width - 20;
  line-height: $level2-height - 30;
  margin-top: 40px;
  transition: left 1s, opacity 1s, height 1s, width 1s, margin-top 1s,
    line-height 1s;
}

.noselect {
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
