.about-ssg {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #181817;
  padding-bottom: 188px;
  flex: 1;
  font-family: "Baskerville";
  .carousel-item{
    display: flex !important;    
    justify-content: space-around;
  }
  .slick-list{
    width: 100%;
  }
  .slick-slider{
    height: 240px;
    img{
      width: 355px;
      height: 200px;
      object-fit: fill;
    }
  }
  .title {
    color: #fff;
    font-weight: 700;
    @media only screen and (min-width: 200px) {
      font-size: 32px;
    }
    @media only screen and (min-width: 1024px) {
      font-size: 48px;
    }
  }
  .tile-mt {
    margin-top: 40px;
  }
  .back-ground {
    margin: 40px auto 0 auto;
    @media only screen and (min-width: 200px) {
      width: 94vw;
    }
    @media only screen and (min-width: 1024px) {
      width: 73vw;
    }
  }
  .content {
    @media only screen and (min-width: 200px) {
      width: 94vw;
    }
    @media only screen and (min-width: 1024px) {
      width: 73vw;
    }
    color: #fff;
    line-height: 30px;
    @media only screen and (min-width: 200px) {
      font-size: 13px;
    }
    @media only screen and (min-width: 1024px) {
      font-size: 18px;
    }
    .head {
      font-weight: 700;
      margin-top: 40px;
    }
    .des {
      font-weight: 400;
    }
  }

  .list-product {
    margin-top: 90px;
    width: 100%;
    @media only screen and (min-width: 200px) {
      width: 94vw;
    }
    @media only screen and (min-width: 1024px) {
      width: 85vw;
    }
    text-align: center;
    .title {
      font-size: 48px;
      line-height: 58px;
      margin-bottom: 57px;
    }
    .c-carousel-root {
      margin: auto;
      height: 154px;
      width: 100%;
      .thumbnail-item {
        display: inline-flex;
        width: 300px;
        margin: 0 10px;
        img {
          width: 100%;
          object-fit: cover;
        }
      }
      .list-control {
        display: none;
      }
    }
  }
}
