.artist {
  display: flex;
  flex-direction: column;
  margin: 40px auto auto auto;
  align-items: flex-start;
  .chuck-list {
    display: flex;
    flex-direction: row;
    justify-content: center;
    flex-wrap: wrap;
    .box-art {
      display: flex;
      flex-direction: column;
      @media only screen and (min-width: 200px) {
        padding: 4px;
      }
      @media only screen and (min-width: 1024px) {
        padding: 20px;
      }
      .img-art {
        @media only screen and (min-width: 200px) {
          width: 168px;
          height: 270px;
        }
        @media only screen and (min-width: 1024px) {
          width: 250px;
          height: 350px;
        }
        object-fit: cover;
        cursor: pointer;
      }
      .footer {
        background-color: rgba(255, 255, 255, 0.1);
        > div {
          color: #fff;
          font-size: 20px;
          font-weight: 600;
          margin: 8px auto 8px 12px;
        }
      }
    }
  }
}
