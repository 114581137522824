.contact-ssg {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #181817;
  padding-bottom: 105px;
  flex: 1;
  font-family: "Baskerville";
  .title {
    color: #fff;
    font-weight: 700;
    @media only screen and (min-width: 200px) {
      font-size: 32px;
    }
    @media only screen and (min-width: 1024px) {
      font-size: 48px;
    }
  }
  .tile-mt {
    margin-top: 40px;
  }
  .back-ground {
    margin: 40px auto 0 auto;
    @media only screen and (min-width: 200px) {
      width: 94vw;
    }
    @media only screen and (min-width: 1024px) {
      width: 73vw;
    }
  }
  .content {
    @media only screen and (min-width: 200px) {
      width: 94vw;
    }
    @media only screen and (min-width: 1024px) {
      width: 73vw;
    }
    color: #fff;
    line-height: 30px;
    @media only screen and (min-width: 200px) {
      font-size: 13px;
    }
    @media only screen and (min-width: 1024px) {
      font-size: 18px;
    }
    .head {
      font-weight: 700;
      margin-top: 40px;
    }
    .des {
      font-weight: 400;
    }
  }
  .button {
    background-color: rgba(255, 255, 255, 0.08);
    @media only screen and (min-width: 200px) {
      width: 300px;
    }
    @media only screen and (min-width: 1024px) {
      width: 384px;
    }
    font-size: 18px;
    font-weight: normal;
    color: #fff;
    text-decoration: unset;
    padding: 8px 80px 8px 80px !important;
    align-items: center;
    cursor: pointer;
  }
}
