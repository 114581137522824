.home {
  max-width: 100%;
  margin: 0 auto;
  position: relative;
  padding-right: 3vw;
  padding-left: 3vw;
  margin-bottom: 3vh;
}

.logo{
  display: flex;
  justify-content: center;
}

.logo img{
  width: 10%;
}
@media only screen and (max-width: 400px)  {
  .logo img{
    width: 25%;
  }
}

#homepage-video{
  width: 100%;
  -moz-transform:scale(2.0);
  -webkit-transform:scale(2.0);
  -o-transform:scale(2.0);
  -ms-transform:scale(2.0);
  transform:scale(2.0);
}

#homepage-video-mobile {
  -moz-transform:scale(1.3);
  -webkit-transform:scale(1.3);
  -o-transform:scale(1.3);
  -ms-transform:scale(1.3);
  transform:scale(1.3);
}

@media only screen and (max-width: 1600px) {
  #homepage-video{
    -moz-transform:scale(2.5);
    -webkit-transform:scale(2.5);
    -o-transform:scale(2.5);
    -ms-transform:scale(2.5);
    transform:scale(2.5);
  }
}
// @media screen (max-width:){
  
// }
.video-meta{
  margin: 82px 0px 40px 0px;
  text-align: center;
  width: 100%;
  height: 100%;
  background-color: black;
}

.video-meta.mobile{
  margin: 0px 0px 0px 0px;
  text-align: center;
}

#ytp-caption-window-container{
  display: none;
}

.homepage-card-time {
  font-family: 'Montserrat' !important;
}

.homepage-card-name {
  font-family: 'Montserrat' !important;
}

.video-meta h3{
  font-family: 'Montserrat' !important;
  font-size: 15px;
}

.video-meta h3 a{
  color: white;
  text-decoration: none !important;
}

.post-grid {
  position: relative;
  overflow: hidden;
  margin-top: 90px;
}

.home #content {
  width: 100%;
  float: none;
  margin-top: 30px;
}

// mobile

.homepage-list-card-mobile {
  width: 30%;
}

@media only screen and (max-width: 768px) {
  .homepage-list-card-mobile {
    width: 45% !important;
  }
}

@media only screen and (max-width: 471px) {
  .homepage-list-card-mobile {
    width: 100% !important;
    height: auto !important;
  }
}
